@import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;0,800;1,400&display=swap");
@import "variables.scss";

$rotateSpeed: 700s;

.webLabel {
  background-color: white;
  padding: 8px 12px;
  position: absolute;
  z-index: 2;
  border-radius: 18px;
  font-weight: bold;
  text-align: center;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.39);
  opacity: 0;
  transition: 0.3s;
  margin-left: 50px;
}

.marketing {
  .webLabel {
    opacity: 1;
    pointer-events: none;
  }
}

.slideInner {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root {
  @keyframes backgroundColorPalette {
    0% {
      background: rgb(221 238 241);
    }

    50% {
      background: white;
    }

    100% {
      background: rgb(221 238 241);
    }
  }

  width: 100%;
  height: 100%;

  @media (max-width: 695px) {
    .hubResponsiveWrapper {
      transform: scale(0.45);
    }

    .videoWrapper {
      width: 95vw !important;
      margin-top: -100px;
    }

    h1 {
      font-size: 24px;
      padding: 60px 20px 30px 100px;
    }

    .videoPlayer {
      width: 95vw !important;
    }

    .logo {
      display: none;
    }

    .setZoom {
      display: none;
    }

    .labelWrapper {
      transform: scale(0.5) !important;
    }
  }
}

.logo {
  position: fixed;
  left: 30px;
  bottom: 30px;
  width: 13vw;
  display: block;
  transition: 0.3s;
  border-radius: 5px;

  &.hide {
    bottom: -150px;
  }
}

body {
  padding: 0 !important;
  margin: 0;
  overflow: hidden;
  font-family: "Nunito", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.labelWrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    animation: flipInX 0.75s forwards;
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    max-width: 400px;
  }
}

.setZoom {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  opacity: 0;
  transition: 0.4s;
  padding: 30px;

  &:hover {
    opacity: 1;
  }

  div {
    margin-left: 10px;
    border-radius: 10px;
    border: 3px solid #d8d8d8;
    cursor: pointer;
    width: 50px;
    height: 50px;
    max-width: 3vw;
    max-height: 3vw;
    background-color: rgba(250, 250, 250, 0.5);
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    transition: 0.3s;

    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }
  }

  div:first-child {
    background-image: url("../img/minus.svg");
  }

  div:last-child {
    background-image: url("../img/plus.svg");
  }
}

.appWrapper {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;

  &.marketing {
    .featuresWrapper .column {
      width: auto;
      height: auto;
      flex: 1;

      @media (max-width: 925px) {
        display: none;
      }
    }

    .labelWrapper span {
      font-size: 42px;
      animation: fadeIn 0.45s forwards;
      max-width: 540px;

      span {
        line-height: 40px;
      }
    }

    .hoverLabel {
      span {
        display: block;
      }

      p {
        font-size: 28px;
        max-width: 550px;
      }
    }
  }

  &.badges {
    background: #81c9d8;
  }

  &.mobile {
    background: #81c9d8;
  }

  &.sync {
    background: #6e6f6e;
  }

  &.reporting {
    background: rgb(110, 111, 110);
  }

  &.google {
    background: #555;
  }

  &.feedback {
    background: #b3b3b3;
  }

  &.guided {
    background: rgb(112, 196, 213);
  }

  &.dashboard {
    background: #acd189;
  }

  &.community {
    background: #81c9d8;
  }

  &.external {
    background: #aed38c;
  }

  &.hq {
    background: #a5a29f;
  }
}

.hubContainer {
  opacity: 1;
  transition: 0.5s;
  transform: scale(1) rotate(-86deg);

  &.selected {
    transform: scale(0.4);
    opacity: 0;
  }
}

.back {
  position: fixed;
  cursor: pointer;
  bottom: 10px;
  left: 10px;
  z-index: 123;
  transition: 0.3s;
  background-size: 50px;
  background-position: center;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  padding: 20px 20px 20px 30px;
  background-image: url("../img/back.svg");
  opacity: 1;

  &:hover {
    opacity: 0.8;
  }
}

.slide {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0px;
  flex-direction: column;
  color: white;

  h1 {
    position: fixed;
    bottom: 8px;
    left: 10px;
    margin: 0;
    right: 0;
    padding: 60px 20px 25px 100px;
    animation: fadeIn 1s forwards;
    z-index: 123;
  }

  .playButton {
    background-image: url("../img/play.svg");
    background-size: 100px;
    background-repeat: no-repeat;
    background-position: center;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0.8;
    transition: 0.4s;
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 12;
    animation: fadeIn 0.5s forwards;

    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
      opacity: 1;
    }
  }

  p {
    margin: 0;
    max-width: 1024px;
    border-radius: 0 0 10px 10px;
    color: white;
  }

  .videoWrapper {
    width: 65vw;
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    animation: backInUp 0.75s forwards;
  }

  .video {
    position: relative;
    cursor: pointer;
  }

  video {
    position: relative;
    border-radius: 10px;
  }
}

.description {
  opacity: 0;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
  font-size: 18px;
  position: absolute;
  right: 25px;
  top: 25px;
  border-radius: 6px;
  padding: 25px;
  background: rgba(0, 0, 0, 0.7);
  z-index: 15;
  width: 625px;
  display: flex;

  @media (max-width: 1100px) {
    font-size: 14px;
    width: 380px;
  }

  .icon {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    position: relative;
    top: 5px;
    margin-right: 25px;
  }
}

#hubImage {
  width: 1200px;
  height: 1200px;
  transition: 1s;
  transform: scale(1);

  path {
    transition: 0.3s;
  }

  &.hovering.google {
    path:not(.google),
    .panel:not(.google) {
      transform: scale(1) translate(0, 0);
    }
  }

  &.hovering.feedback {
    path:not(.feedback),
    .panel:not(.feedback) {
      transform: scale(1) translate(0, 0);
    }
  }

  &.hovering.sync {
    path:not(.sync),
    .panel:not(.sync) {
      transform: scale(1) translate(0, 0);
    }
  }

  &.hovering.reporting {
    path:not(.reporting),
    .panel:not(.reporting) {
      transform: scale(1) translate(0, 0);
    }
  }

  &.hovering.guided {
    path:not(.guided),
    .panel:not(.guided) {
      transform: scale(1) translate(0, 0);
    }
  }

  &.hovering.dashboard {
    path:not(.dashboard),
    .panel:not(.dashboard) {
      transform: scale(1) translate(0, 0);
    }
  }

  &.hovering.community {
    path:not(.community),
    .panel:not(.community) {
      transform: scale(1) translate(0, 0);
    }
  }

  &.hovering.external {
    path:not(.external),
    .panel:not(.external) {
      transform: scale(1) translate(0, 0);
    }
  }

  &.hovering.mobile {
    path:not(.mobile),
    .panel:not(.mobile) {
      transform: scale(1) translate(0, 0);
    }
  }

  &.hovering.badges {
    path:not(.badges),
    .panel:not(.badges) {
      transform: scale(1) translate(0, 0);
    }
  }

  &.hovering.hq {
    path:not(.hq),
    .panel:not(.hq) {
      transform: scale(1) translate(0, 0);
    }
  }
}

.hubWrapper {
  position: relative;
  animation: rotate $rotateSpeed infinite linear;
  z-index: 4;

  &.noAnimate {
    animation: none;

    .icon {
      animation: none !important;
    }
  }

  &.bg {
    pointer-events: none;
    z-index: 0;
    top: -50vw;
    left: -50vw;
    position: fixed;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vw;
    width: 100vw;
    opacity: 0.05;
    background-image: url("../img/mm-logo-large.svg");

    &.two {
      top: 50vh;
      right: -50vw;
      left: auto;
    }
  }
}

svg path {
  z-index: 1;
  position: relative;
}

.panel {
  cursor: pointer !important;
  transition: 0.3s;
  transform: scale(1) translate(0, 0);

  &:hover,
  &.active {
    fill: rgba(250, 250, 250, 0.95) !important;
  }

  &.google {
    fill: #555;
    transform: translate(-9px, -24px) scale(1.2);

    &:hover {
      transform: translate(-13.5px, -35.5px) scale(1.3);
    }
  }

  &.dashboard {
    transform: translate(-7px, -15px) scale(1.2);

    &:hover {
      transform: translate(-10px, -23px) scale(1.3);
    }
  }

  &.external {
    transform: translate(-39px, -23.5px) scale(1.2);

    &:hover {
      transform: translate(-58px, -35px) scale(1.3);
    }
  }

  &.guided {
    fill: rgb(129, 201, 216);
    transform: translate(-17px, -9px) scale(1.2);

    &:hover {
      transform: translate(-26px, -14px) scale(1.3);
    }

    &.marketing {
      transform: translate(-15px, -5px) scale(1.2);
    }
  }

  &.hq {
    transform: translate(-34px, -17px) scale(1.2);

    &:hover {
      transform: translate(-50.6px, -25.5px) scale(1.3);
    }

    &.marketing {
      transform: translate(-38px, -19px) scale(1.2);

      &:hover {
        transform: translate(-57.6px, -28.5px) scale(1.3) !important;
      }
    }
  }

  &.feedback {
    transform: translate(-24px, -5px) scale(1.2);

    &:hover {
      transform: translate(-36px, -7px) scale(1.3);
    }
  }

  &.badges {
    transform: translate(-31px, -11px) scale(1.2);

    &:hover {
      transform: translate(-45px, -17px) scale(1.3);
    }
  }

  &.community {
    fill: rgb(129, 201, 216);
    transform: translate(-38px, -24px) scale(1.2);

    &:hover {
      transform: translate(-58px, -36px) scale(1.3);
    }
  }

  &.sync {
    transform: translate(-32px, -30px) scale(1.2);

    &:hover {
      transform: translate(-48px, -45px) scale(1.3);
    }
  }

  &.external {
    transform: translate(-29px, -37px) scale(1.2);

    &:hover {
      transform: translate(-42px, -56px) scale(1.3);
    }
  }

  &.mobile {
    fill: rgb(129, 201, 216);
    transform: translate(-20px, -35px) scale(1.2);

    &:hover {
      transform: translate(-30.5px, -52px) scale(1.3);
    }
  }

  &.reporting {
    transform: translate(-12px, -35px) scale(1.2);

    &:hover {
      transform: translate(-18px, -52px) scale(1.3);
    }
  }
}

.icon {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}

.iconWrapper {
  position: absolute;
  transform: rotate(87deg);

  &.google {
    left: 271px;
    top: 612px;
  }

  &.community {
    left: 1084px;
    top: 624px;
  }

  &.feedback {
    left: 690px;
    top: 116px;
  }

  &.guided {
    left: 492px;
    top: 238px;

    &.marketing {
      left: 462px;
      top: 153px;
    }
  }

  &.dashboard {
    left: 205px;
    top: 396px;
  }

  &.badges {
    left: 846px;
    top: 281px;
  }

  &.hq {
    left: 965px;
    top: 427px;

    &.marketing {
      left: 1087px;
      top: 510px;
    }
  }

  &.sync {
    left: 918px;
    top: 787px;
  }

  &.mobile {
    left: 579px;
    top: 930px;
  }

  &.reporting {
    left: 356px;
    top: 924px;
  }

  &.external {
    left: 804px;
    top: 987px;
  }
}

.hub-icons {
  position: relative;
  z-index: 2;
  animation: fadeIn 1s forwards;
  cursor: pointer;

  &.hovering {
    .icon.active {
      background-size: 60px;

      &.community {
        background-image: url("../img/community-color.svg");
      }

      &.badges {
        background-image: url("../img/badge-color.svg");
      }

      &.external {
        background-image: url("../img/external-pd-color.svg");
      }

      &.dashboard {
        background-image: url("../img/dashboard-color.svg");
      }

      &.sync {
        background-image: url("../img/sync-color.svg");
      }

      &.mobile {
        background-image: url("../img/mobile-color.svg");
      }

      &.reporting {
        background-image: url("../img/reports-color.svg");
      }

      &.feedback {
        background-image: url("../img/feedback-color.svg");
      }

      &.guided {
        background-image: url("../img/guided-color.svg");
      }

      &.hq {
        background-image: url("../img/create-color.svg");
      }

      &.google {
        background-image: url("../img/ready-made-color.svg");
      }
    }

    .icon:not(.active) {
      opacity: 0.8;
      background-size: 35px;
    }
  }

  .icon {
    position: absolute;
    pointer-events: none;
    transition: 0.3s;
    transform: scale(1);
    width: 65px;
    height: 65px;
    background-size: 50px;
    animation: rotate $rotateSpeed infinite linear;
    animation-direction: reverse;
  }
}

.hoverLabel {
  p {
    font-weight: normal;
    font-size: 18px;
  }

  .green {
    color: $green;
  }

  .blue {
    color: $blue;
  }
}

.icon {
  &.community {
    background-image: url("../img/community.svg");
  }

  &.feedback {
    background-image: url("../img/feedback.svg");
  }

  &.google {
    background-image: url("../img/ready-made.svg");
  }

  &.guided {
    background-image: url("../img/guided.svg");
  }

  &.badges {
    background-image: url("../img/badge.svg");
  }

  &.hq {
    background-image: url("../img/create.svg");
  }

  &.sync {
    background-image: url("../img/sync.svg");
  }

  &.mobile {
    background-image: url("../img/mobile.svg");
  }

  &.reporting {
    background-image: url("../img/reports.svg");
  }

  &.dashboard {
    background-image: url("../img/dashboard.svg");
  }

  &.external {
    width: 65px;
    height: 65px;
    background-image: url("../img/external-pd.svg");
  }
}

.mobileLayout {
  display: flex;
  justify-content: center;
  align-items: center;

  .description {
    animation-delay: 2s;
  }

  img {
    transform: translateX(-2000px) scale(0.7);
    opacity: 0.7;
    display: block;
    animation: backInLeft 0.5s forwards;
    margin-top: 100px;
    margin-right: -100px;
    max-width: 40vw;
    max-height: 90vh;
  }
}

.featuresWrapper {
  display: none;

  @media (min-width: 1920px) {
    display: flex;
  }

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  padding: 25px;
  align-items: center;
  justify-content: space-between;

  &.marketing {
    display: flex;

    .column {
      max-width: 25%;
    }
  }

  .column {
    height: 50vh;
    width: 20vw;
    display: flex;
    flex-direction: column;
    text-align: center;
    z-index: 10;
    align-items: center;

    h2 {
      font-size: 24px;
      animation: backInUp 0.75s forwards;
      color: rgba(0, 0, 0, 0.8);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0);
      transition: 0.4s;
      cursor: pointer;
    }
  }

  &.marketing .column h2 {
    padding: 35px 50px;
    border-radius: 150px;
    font-size: 48px;
    height: 165px;
    background-color: #59cbe8;
    width: 100%;
    color: white;
    margin: 15px;
    box-sizing: border-box;

    .icon {
      margin-right: 15px;
    }

    &:hover {
      background-color: rgb(36, 220, 145);
    }
  }
}
